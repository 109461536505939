<template>
  <v-dialog
    v-if="editedVehicle"
    :value="dialog"
    :persistent="isModelChanged()"
    :max-width="680"
    content-class="d-dialog"
    scrollable
    @keydown.esc="escape(closeDialog)"
    @click:outside="escape(closeDialog)"
  >
    <v-card outlined class="d-dialog-card">
      <v-card-title>
        {{ $t('vehicle.createVehicle') }}
        <d-btn-icon
          type="close"
          btn-class="d-dialog-btn-close"
          tabindex="4"
          @click="escape(closeDialog)"
        ></d-btn-icon>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form" @submit.stop.prevent>
          <div class="d-fieldset">
            <v-icon class="d-fieldset-icon">mdi-car</v-icon>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="editedVehicle.licenseNumber"
                  :rules="rules.licenseNumber"
                  :label="$t('vehicle.edit.licenseNumber')"
                  :hint="$t('vehicle.edit.licenseNumber_hint')"
                  tabindex="1"
                  clearable
                  autofocus
                  @keydown="preventWhitespaceInput"
                  @input="onLicenseNumberInput"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <d-date-picker
                  v-model="editedVehicle.registrationDate"
                  :label="$t('vehicle.edit.registrationDate')"
                  tabindex="1"
                  clearable
                ></d-date-picker>
              </v-col>
              <v-col cols="12" md="6">
                <v-combobox
                  v-model.trim="editedVehicle.brand"
                  :rules="rules.brand"
                  :label="$t('vehicle.edit.brand')"
                  :items="vehicleBrands"
                  append-icon=""
                  tabindex="1"
                  clearable
                  auto-select-first
                  hide-no-data
                  @change="loadModelsByBrand"
                ></v-combobox>
              </v-col>
              <v-col cols="12" md="6">
                <v-combobox
                  v-model.trim="editedVehicle.model"
                  :rules="rules.model"
                  :label="$t('vehicle.edit.model')"
                  :items="vehicleModels(editedVehicle.brand)"
                  append-icon=""
                  tabindex="1"
                  clearable
                  auto-select-first
                  hide-no-data
                ></v-combobox>
              </v-col>
            </v-row>
          </div>

          <div class="d-fieldset">
            <v-icon class="d-fieldset-icon">mdi-account</v-icon>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="editedVehicle.firstName"
                  :rules="rules.firstName"
                  :label="$t('vehicle.edit.firstName')"
                  tabindex="1"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="editedVehicle.lastName"
                  :rules="rules.lastName"
                  :label="$t('vehicle.edit.lastName')"
                  tabindex="1"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <d-phone-input
                  v-model="editedVehicle.phone"
                  default-country="NO"
                  :rules="rules.phone"
                  :label="$t('vehicle.edit.phone')"
                  tabindex="1"
                  clearable
                ></d-phone-input>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="editedVehicle.emailAddress"
                  :rules="rules.emailAddress"
                  validate-on-blur
                  :label="$t('vehicle.edit.email')"
                  type="email"
                  tabindex="1"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <div class="d-fieldset mb-2">
            <v-icon class="d-fieldset-icon">mdi-map-marker</v-icon>
            <v-row>
              <v-col cols="12">
                <d-google-places-autocomplete
                  v-model.trim="editedVehicle.address"
                  :rules="rules.address"
                  :label="$t('vehicle.edit.address')"
                  tabindex="1"
                  clearable
                  @geocoded="onAddressGeocoded"
                ></d-google-places-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="editedVehicle.zipCode"
                  :rules="rules.zipCode"
                  :label="$t('vehicle.edit.zipCode')"
                  tabindex="1"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="editedVehicle.area"
                  :rules="rules.area"
                  :label="$t('vehicle.edit.area')"
                  tabindex="1"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          v-text="$t('cancel')"
          color="secondary"
          text
          tabindex="3"
          @click="closeDialog"
        ></v-btn>
        <v-btn
          v-text="$t('save')"
          :disabled="!isModelChanged()"
          color="primary"
          text
          tabindex="2"
          @click="submit"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { vehicleSchema } from '@/constants'

import objectUtil from '@/utils/object.util'

import dialogMixin from '@/mixins/dialog.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import validationMixin from '@/mixins/validation.mixin'
import unsavedChangesMixin from '@/mixins/unsavedChanges.mixin'
import errorMixin from '@/mixins/error.mixin'
import licenseNumberMixin from '@/mixins/licenseNumber.mixin'
import vehicleBrandsAndModelsMixin from '@/mixins/vehicleBrandsAndModels.mixin'

import DBtnIcon from '@/components/DBtnIcon'
import DDatePicker from '@/components/DDatePicker'
import DPhoneInput from '@/components/DPhoneInput'
import DGooglePlacesAutocomplete from '@/components/DGooglePlacesAutocomplete'


export default {
  props: {
    licenseNumber: {
      type: String,
      default: ''
    }
  },


  components: {
    DBtnIcon,
    DDatePicker,
    DPhoneInput,
    DGooglePlacesAutocomplete
  },


  mixins: [
    dialogMixin,
    snackbarMixin,
    validationMixin,
    unsavedChangesMixin,
    errorMixin,
    licenseNumberMixin,
    vehicleBrandsAndModelsMixin
  ],


  data () {
    return {
      editedVehicle: null,
      defaultVehicle: {
        licenseNumber: '',
        registrationDate: null,
        brand: '',
        model: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phone: '',
        address: '',
        area: '',
        zipCode: ''
      },
      existingLicenseNumbers: []
    }
  },


  computed: {
    ...mapGetters('dealer', ['currentDealerId']),

    rulesToApply () {
      const rule = this.rule

      return {
        licenseNumber: [
          rule.required(),
          rule.maxLength(vehicleSchema.licenseNumberMaxLength),
          rule.unique(this.existingLicenseNumbers, this.$t('vehicle.edit.licenseNumberExists'))
        ],
        brand: [rule.maxLength(vehicleSchema.brandMaxLength)],
        model: [rule.maxLength(vehicleSchema.modelMaxLength)],
        firstName: [rule.maxLength(vehicleSchema.firstNameMaxLength)],
        lastName: [rule.maxLength(vehicleSchema.lastNameMaxLength)],
        phone: [rule.required(), rule.phone(), rule.maxLength(vehicleSchema.phoneMaxLength)],
        emailAddress: [rule.email(), rule.maxLength(vehicleSchema.emailMaxLength)],
        address: [rule.maxLength(vehicleSchema.addressMaxLength)],
        area: [rule.maxLength(vehicleSchema.areaMaxLength)],
        zipCode: [rule.maxLength(vehicleSchema.zipCodeMaxLength)]
      }
    }
  },


  methods: {
    ...mapActions('vehicle', ['createVehicle']),

    onDialogOpen () {
      this.existingLicenseNumbers = []
      this.editedVehicle = objectUtil.getObjectCopy(this.defaultVehicle)

      this.initWatchedModel(this.editedVehicle)
      this.resetValidation()

      this.editedVehicle.licenseNumber = this.licenseNumber
    },

    onLicenseNumberInput () {
      this.editedVehicle.licenseNumber = this.fixLicenseNumber(this.editedVehicle.licenseNumber)
    },

    onAddressGeocoded (address) {
      this.editedVehicle.address = address.streetAddress
      this.editedVehicle.zipCode = address.zipCode
      this.editedVehicle.area = address.area
    },

    async submit () {
      const valid = await this.validate(this.rulesToApply)
      if (!valid || !this.isModelChanged()) return

      try {
        this.showSnackbarProcessing()

        let vehicle = objectUtil.getObjectCopy(this.editedVehicle)
        vehicle.dealerId = this.currentDealerId
        vehicle = await this.createVehicle(vehicle)

        this.showSnackbarSuccess(this.$t('vehicle.vehicleCreated'))
        this.closeDialog()
        this.$emit('create', vehicle)
      } catch (error) {
        if (this.is409ConflictError(error)) {
          this.hideSnackbar()
          this.existingLicenseNumbers.push(this.editedVehicle.licenseNumber)
          this.validate(this.rulesToApply)
        } else {
          this.showSnackbarError()
        }
        this.$log.error(error)
      }
    }
  }
}
</script>
