var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"d-data-table d-data-table--filled d-data-table--rows-clickable",attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"loading":_vm.loading,"loader-height":2,"sort-by":"date","sort-desc":"","hide-default-footer":""},on:{"click:row":_vm.openEditDialog},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateTimeUtil.utcToLocalDisplayDateFormat(item.date))+" ")]}},{key:"item.mileage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mileage)+" "+_vm._s(_vm.$t('service.mileageSuffix'))+" ")]}},{key:"item.glassProtection",fn:function(ref){
var item = ref.item;
return [(item.glassProtection)?_c('v-icon',{attrs:{"color":"d-green"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('d-btn-icon',{attrs:{"type":"edit"},on:{"click":function($event){return _vm.openEditDialog(item)}}}),_c('d-btn-icon',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.deleteService_(item)}}})],1)]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('v-divider'),_c('div',{staticClass:"d-flex flex-wrap justify-space-between align-center"},[_c('v-btn',{staticClass:"d-btn-1 my-3 ml-4",attrs:{"small":"","depressed":"","tabindex":"2"},domProps:{"textContent":_vm._s('+ ' + _vm.$t('service.createService'))},on:{"click":function($event){return _vm.openEditDialog(null)}}}),_c('v-data-footer',{staticClass:"d-border-none text-no-wrap",attrs:{"pagination":props.pagination,"options":props.options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText"}})],1)]}}],null,true)}),_c('service-edit-dialog',{attrs:{"dialog":_vm.editDialog,"service":_vm.editDialog_service,"vehicle-id":_vm.vehicleId},on:{"update:dialog":function($event){_vm.editDialog=$event},"create":function (service) { return _vm.$emit('service-create', service); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }