import { mapGetters, mapActions } from 'vuex'


const mixin = {
  computed: {
    ...mapGetters('vehicle', ['vehicleBrands', 'vehicleModels'])
  },


  methods: {
    ...mapActions('vehicle', ['loadVehicleModels']),

    async loadModelsByBrand (brand) {
      try {
        if (this.isFalseOrWhitespace(brand) || this.vehicleModels(brand).length) return

        await this.loadVehicleModels(brand)
      } catch (error) {
        this.$log.error(error)
      }
    }
  }
}


export default mixin
