<template>
  <v-text-field
    ref="autocomplete"
    v-model="model"
    v-bind="$attrs"
    type="text"
    autocomplete="off"
    @keydown.enter.stop
    @input="placeChanged"
  ></v-text-field>
</template>


<script>
export default {
  inheritAttrs: false,


  props: {
    value: {
      type: String
    }
  },


  data () {
    return {
      autocomplete: null,
      googleScriptLoaded: false
    }
  },


  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },


  methods: {
    initAutocomplete () {
      const options = {
        componentRestrictions: { country: 'no' },
        types: ['address'],
        fields: ['name', 'address_components']
      }

      if (this.$refs.autocomplete) {
        this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs.autocomplete.$refs.input, options)
        // overriding google placeholder with our placeholder
        this.$refs.autocomplete.$refs.input.setAttribute('placeholder', this.$attrs.placeholder || '')
        window.google.maps.event.addListener(this.autocomplete, 'place_changed', this.placeChanged)
      }
    },

    placeChanged () {
      const place = this.autocomplete.getPlace()

      if (place) {
        const address = this.getAddressFromPlace(place)
        if (address) {
          this.$emit('geocoded', address)
        }
      }
    },

    getAddressFromPlace (place) {
      if (!place?.address_components) return null

      const address = {
        streetAddress: place.name
      }

      for (const component of place.address_components) {
        const componentType = component.types[0]

        switch (componentType) {
          case 'postal_code': {
            address.zipCode = component.long_name
            break
          }

          case 'postal_town': {
            address.area = component.long_name
            break
          }

          case 'locality': {
            address.area = address.area || component.long_name
            break
          }
        }
      }

      return address
    }
  },


  mounted () {
    if (document.getElementById('GoogleMapsScript') === null) {
      const googleScript = document.createElement('script')
      googleScript.setAttribute('id', 'GoogleMapsScript')
      googleScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.VUE_APP_GOOGLE_API_KEY}`)
      googleScript.onload = () => {
        this.googleScriptLoaded = true
        this.initAutocomplete()
      }
      document.head.appendChild(googleScript)
    } else {
      this.googleScriptLoaded = true
    }

    if (this.googleScriptLoaded && !this.autocomplete) {
      this.initAutocomplete()
    }
  }
}
</script>
