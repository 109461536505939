<template>
  <v-dialog
    v-if="editedVehicle"
    :value="dialog"
    :persistent="isModelChanged()"
    content-class="d-dialog-fullscreen d-vehicle-edit-dialog"
    fullscreen
    @keydown.esc="onEscape"
  >
    <v-card tile>
      <v-toolbar color="primary" dark>
        <v-btn
          icon
          tabindex="3"
          @click="onEscape"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('vehicle.vehicleDetails') }}</v-toolbar-title>
      </v-toolbar>

      <div class="d-dialog-fullscreen-content">
        <v-row>
          <v-col cols="12" lg="5">
            <v-card outlined>
              <v-card-text>
                <v-form ref='form' @submit.stop.prevent>
                  <div class="d-fieldset">
                    <v-icon class="d-fieldset-icon">mdi-car</v-icon>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model.trim="editedVehicle.licenseNumber"
                          :rules="rules.licenseNumber"
                          :label="$t('vehicle.edit.licenseNumber')"
                          :hint="$t('vehicle.edit.licenseNumber_hint')"
                          tabindex="1"
                          clearable
                          autofocus
                          @keydown="preventWhitespaceInput"
                          @input="onLicenseNumberInput"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <d-date-picker
                          v-model="editedVehicle.registrationDate"
                          :label="$t('vehicle.edit.registrationDate')"
                          tabindex="1"
                          clearable
                        ></d-date-picker>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-combobox
                          v-model.trim="editedVehicle.brand"
                          :rules="rules.brand"
                          :label="$t('vehicle.edit.brand')"
                          :items="vehicleBrands"
                          append-icon=""
                          tabindex="1"
                          clearable
                          auto-select-first
                          hide-no-data
                          @change="loadModelsByBrand"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-combobox
                          v-model.trim="editedVehicle.model"
                          :rules="rules.model"
                          :label="$t('vehicle.edit.model')"
                          :items="vehicleModels(editedVehicle.brand)"
                          append-icon=""
                          tabindex="1"
                          clearable
                          auto-select-first
                          hide-no-data
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="d-fieldset">
                    <v-icon class="d-fieldset-icon">mdi-account</v-icon>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model.trim="editedVehicle.firstName"
                          :rules="rules.firstName"
                          :label="$t('vehicle.edit.firstName')"
                          tabindex="1"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model.trim="editedVehicle.lastName"
                          :rules="rules.lastName"
                          :label="$t('vehicle.edit.lastName')"
                          tabindex="1"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <d-phone-input
                          v-model="editedVehicle.phone"
                          default-country="NO"
                          :rules="rules.phone"
                          :label="$t('vehicle.edit.phone')"
                          tabindex="1"
                          clearable
                        ></d-phone-input>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model.trim="editedVehicle.emailAddress"
                          :rules="rules.emailAddress"
                          validate-on-blur
                          :label="$t('vehicle.edit.email')"
                          type="email"
                          tabindex="1"
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="d-fieldset">
                    <v-icon class="d-fieldset-icon">mdi-map-marker</v-icon>
                    <v-row>
                      <v-col cols="12">
                        <d-google-places-autocomplete
                          v-model.trim="editedVehicle.address"
                          :rules="rules.address"
                          :label="$t('vehicle.edit.address')"
                          tabindex="1"
                          clearable
                          @geocoded="onAddressGeocoded"
                        ></d-google-places-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model.trim="editedVehicle.zipCode"
                          :rules="rules.zipCode"
                          :label="$t('vehicle.edit.zipCode')"
                          tabindex="1"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model.trim="editedVehicle.area"
                          :rules="rules.area"
                          :label="$t('vehicle.edit.area')"
                          tabindex="1"
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="d-fieldset">
                    <v-icon class="d-fieldset-icon">mdi-calendar</v-icon>
                    <v-row>
                      <v-col cols="12" md="6">
                        <d-date-picker
                          v-model="editedVehicle.nextNotificationDate"
                          :min="nextNotificationMinDate"
                          :label="$t('vehicle.edit.nextNotification')"
                          tabindex="1"
                          clearable
                        ></d-date-picker>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="d-fieldset mb-2">
                    <v-icon class="d-fieldset-icon">mdi-text</v-icon>
                    <v-textarea
                      v-model.trim="editedVehicle.comment"
                      :rules="rules.comment"
                      :label="$t('vehicle.edit.comment')"
                      rows="1"
                      tabindex="1"
                      clearable
                      auto-grow
                    ></v-textarea>
                  </div>
                </v-form>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  v-text="$t('vehicle.edit.save')"
                  :disabled="!isModelChanged()"
                  color="primary"
                  text
                  tabindex="2"
                  @click="saveDetails"
                ></v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" lg="7" class="pl-lg-12">
            <div class="text-h6 mb-2">{{ $t('vehicle.edit.services') }}</div>
            <services-table
              :vehicle-id="vehicle.id"
              :items="vehicleServices"
              :loading="servicesLoading"
              @service-create="onServiceCreate"
            ></services-table>

            <div class="text-h6 mb-2 mt-10">{{ $t('vehicle.edit.notifications') }}</div>

            <notifications-table
              :items="vehicleNotifications"
              :loading="notificationsLoading"
            ></notifications-table>

            <v-btn
              v-text="$t('vehicle.edit.sendSMS')"
              class="d-btn-1 my-3"
              small
              depressed
              tabindex="2"
              @click="openSmsDialog"
            ></v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <send-s-m-s-dialog
      :dialog.sync="smsDialog"
      :editedVehicle="editedVehicle"
      @sent="onNotificationSent"
    ></send-s-m-s-dialog>
  </v-dialog>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { vehicleSchema, dateTimeFormats, nextNotificationDateSettings } from '@/constants'

import moment from 'moment'

import objectUtil from '@/utils/object.util'
import dateTimeUtil from '@/utils/dateTime.util'

import dialogMixin from '@/mixins/dialog.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import validationMixin from '@/mixins/validation.mixin'
import unsavedChangesMixin from '@/mixins/unsavedChanges.mixin'
import errorMixin from '@/mixins/error.mixin'
import licenseNumberMixin from '@/mixins/licenseNumber.mixin'
import vehicleBrandsAndModelsMixin from '@/mixins/vehicleBrandsAndModels.mixin'

import DDatePicker from '@/components/DDatePicker'
import DPhoneInput from '@/components/DPhoneInput'
import DGooglePlacesAutocomplete from '@/components/DGooglePlacesAutocomplete'
import ServicesTable from './ServicesTable'
import NotificationsTable from './NotificationsTable'
import SendSMSDialog from './SendSMSDialog'


export default {
  props: {
    vehicle: Object
  },

  components: {
    SendSMSDialog,
    DDatePicker,
    DPhoneInput,
    DGooglePlacesAutocomplete,
    ServicesTable,
    NotificationsTable
  },


  mixins: [
    dialogMixin,
    snackbarMixin,
    validationMixin,
    unsavedChangesMixin,
    errorMixin,
    licenseNumberMixin,
    vehicleBrandsAndModelsMixin
  ],


  data () {
    return {
      editedVehicle: null,
      servicesLoading: true,
      notificationsLoading: true,
      existingLicenseNumbers: [],
      initialNextNotificationDate: null,
      checkNextNotificationDateAdjusted: false,
      emptyNextNotificationDateDeclined: false,
      smsDialog: false
    }
  },


  computed: {
    ...mapGetters('dealer', ['currentDealerId', 'shortDealerById']),
    ...mapGetters('user', ['userById']),
    ...mapGetters('service', ['servicesByVehicle']),
    ...mapGetters('serviceType', ['serviceTypeById']),
    ...mapGetters('notification', ['notificationsByVehicle']),

    rulesToApply () {
      const rule = this.rule

      return {
        licenseNumber: [
          rule.required(),
          rule.maxLength(vehicleSchema.licenseNumberMaxLength),
          rule.unique(this.existingLicenseNumbers, this.$t('vehicle.edit.licenseNumberExists'))
        ],
        brand: [rule.maxLength(vehicleSchema.brandMaxLength)],
        model: [rule.maxLength(vehicleSchema.modelMaxLength)],
        firstName: [rule.maxLength(vehicleSchema.firstNameMaxLength)],
        lastName: [rule.maxLength(vehicleSchema.lastNameMaxLength)],
        phone: [rule.required(), rule.phone(), rule.maxLength(vehicleSchema.phoneMaxLength)],
        emailAddress: [rule.email(), rule.maxLength(vehicleSchema.emailMaxLength)],
        address: [rule.maxLength(vehicleSchema.addressMaxLength)],
        area: [rule.maxLength(vehicleSchema.areaMaxLength)],
        zipCode: [rule.maxLength(vehicleSchema.zipCodeMaxLength)],
        comment: [rule.maxLength(vehicleSchema.commentMaxLength)]
      }
    },

    nextNotificationMinDate () {
      return moment().format(dateTimeFormats.datePicker)
    },

    vehicleServices () {
      return this.servicesByVehicle(this.vehicle.id).map(item => {
        return {
          ...item,
          serviceType: this.serviceTypeById(item.serviceTypeId) || {},
          dealer: this.shortDealerById(item.dealerId) || {}
          // salesPerson: this.userById(item.userId) || {}  //temporary hiding sales person from the project
        }
      })
    },

    vehicleNotifications () {
      return this.notificationsByVehicle(this.vehicle.id)
    }
  },


  methods: {
    ...mapActions('vehicle', ['updateVehicle']),
    ...mapActions('service', ['loadServicesByVehicle']),
    ...mapActions('serviceType', ['loadServiceTypes']),
    ...mapActions('notification', ['loadNotificationsByVehicle']),


    openSmsDialog () {
      this.smsDialog = true
    },

    onDialogOpen () {
      this.existingLicenseNumbers = []
      this.initialNextNotificationDate = this.vehicle.nextNotificationDate
      this.checkNextNotificationDateAdjusted = false
      this.emptyNextNotificationDateDeclined = false

      this.editedVehicle = objectUtil.getObjectCopy(this.vehicle)
      this.initWatchedModel(this.editedVehicle)
      this.resetValidation()

      this.servicesLoading = true
      this.notificationsLoading = true

      this.loadServicesByVehicle(this.vehicle.id)
        .then(() => { this.servicesLoading = false })

      this.loadNotificationsByVehicle(this.vehicle.id)
        .then(() => { this.notificationsLoading = false })
    },

    reloadNotifications () {
      this.notificationsLoading = true
      this.loadNotificationsByVehicle(this.vehicle.id)
        .then(() => { this.notificationsLoading = false })
    },


    onNotificationSent () {
      this.reloadNotifications()
    },

    onLicenseNumberInput () {
      this.editedVehicle.licenseNumber = this.fixLicenseNumber(this.editedVehicle.licenseNumber)
    },

    onAddressGeocoded (address) {
      this.editedVehicle.address = address.streetAddress
      this.editedVehicle.zipCode = address.zipCode
      this.editedVehicle.area = address.area
    },

    async onServiceCreate (service) {
      this.checkNextNotificationDateAdjusted = true

      try {
        if (this.shouldEmptyNextNotificationDate()) {
          await this.suggestEmptyNextNotificationDate()
        } else {
          const suggestedDate = this.getSuggestedNextNotificationDate(service.date)

          if (suggestedDate && suggestedDate !== this.editedVehicle.nextNotificationDate) {
            await this.suggestSetNextNotificationDate(suggestedDate)
          } else {
            this.checkNextNotificationDateAdjusted = false
          }
        }
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      }
    },

    async saveDetails () {
      const valid = await this.validate(this.rulesToApply)
      if (!valid || !this.isModelChanged()) return

      try {
        this.showSnackbarProcessing()

        await this.updateVehicle(this.editedVehicle)
        this.initWatchedModel(this.editedVehicle)

        this.showSnackbarSuccess(this.$t('vehicle.vehicleUpdated'))
      } catch (error) {
        if (this.is409ConflictError(error)) {
          this.hideSnackbar()
          this.existingLicenseNumbers.push(this.editedVehicle.licenseNumber)
          this.validate(this.rulesToApply)
        } else {
          this.showSnackbarError()
        }
        this.$log.error(error)
      }
    },

    async onEscape () {
      if (this.shouldEmptyNextNotificationDate()) {
        await this.suggestEmptyNextNotificationDate()
        return
      } else if (this.checkNextNotificationDateAdjusted && this.initialNextNotificationDate === this.editedVehicle.nextNotificationDate) {
        const confirmEscape = await this.$confirm(this.$t('vehicle.nextNotificationDateNotAdjustedConfirm.message'),
          {
            trueText: this.$t('vehicle.nextNotificationDateNotAdjustedConfirm.trueText'),
            falseText: this.$t('vehicle.nextNotificationDateNotAdjustedConfirm.falseText')
          })

        if (!confirmEscape) {
          return
        }
      }

      await this.escape(this.closeDialog)
    },


    /* Next notification date adjustments
    ------------------------------------------------------- */
    shouldEmptyNextNotificationDate () {
      if (this.emptyNextNotificationDateDeclined || !this.editedVehicle.nextNotificationDate) return false

      const initialTreatments = this.vehicleServices.filter(x => x.serviceType.initialTreatment === true)

      if (initialTreatments.length) {
        const initialTreatmentsSortedDesc = initialTreatments.sort((item1, item2) => new Date(item2.date) - new Date(item1.date))
        const lastInitialTreatmentDate = moment.utc(initialTreatmentsSortedDesc[0].date)
        const validDate = moment.utc().subtract(nextNotificationDateSettings.emptyAfterMonths, 'months')

        if (lastInitialTreatmentDate.isBefore(validDate)) {
          return true
        }
      }

      return false
    },

    async suggestEmptyNextNotificationDate () {
      const confirm = await this.$confirm(this.$t('vehicle.emptyNextNotificationDateConfirm.message'),
        {
          trueText: this.$t('vehicle.emptyNextNotificationDateConfirm.trueText'),
          falseText: this.$t('vehicle.emptyNextNotificationDateConfirm.falseText')
        })

      if (confirm) {
        this.editedVehicle.nextNotificationDate = null
        await this.saveDetails()
      } else {
        this.emptyNextNotificationDateDeclined = true
      }

      return confirm
    },

    async suggestSetNextNotificationDate (date) {
      const dateFormatted = dateTimeUtil.utcToLocalDisplayDateFormat(date)
      const confirm = await this.$confirm(this.$t('vehicle.setNextNotificationDateConfirm.message', { date: dateFormatted }),
        {
          trueText: this.$t('vehicle.setNextNotificationDateConfirm.trueText'),
          falseText: this.$t('vehicle.setNextNotificationDateConfirm.falseText')
        })

      if (confirm) {
        this.editedVehicle.nextNotificationDate = date
        await this.saveDetails()
      }

      return confirm
    },

    getSuggestedNextNotificationDate (date) {
      let suggestedDate = moment.utc(date).add(nextNotificationDateSettings.suggestionAfterMonths, 'months')

      const isValid = (date) => {
        if (nextNotificationDateSettings.suggestionSkipWeekDays.includes(date.day()) ||
            nextNotificationDateSettings.suggestionSkipDates.includes(date.format('DD.MM'))) {
          return false
        }
        return true
      }

      while (!isValid(suggestedDate)) {
        suggestedDate = suggestedDate.add(1, 'days')
      }

      if (suggestedDate.isSameOrBefore(moment.utc())) {
        return null
      }

      return suggestedDate.format(dateTimeFormats.dateTimeUtc)
    }
  },


  async created () {
    try {
      await this.loadServiceTypes()
    } catch (error) {
      this.$log.error(error)
    }
  }
}
</script>
