<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="items"
      :loading="loading"
      :loader-height="2"
      sort-by="date"
      sort-desc
      class="d-data-table d-data-table--filled d-data-table--rows-clickable"
      hide-default-footer
      @click:row="openEditDialog"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ dateTimeUtil.utcToLocalDisplayDateFormat(item.date) }}
      </template>
      <template v-slot:[`item.mileage`]="{ item }">
        {{ item.mileage }} {{ $t('service.mileageSuffix') }}
      </template>
      <template v-slot:[`item.glassProtection`]="{ item }">
        <v-icon v-if="item.glassProtection" color="d-green">mdi-check</v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="text-no-wrap">
          <d-btn-icon type="edit" @click="openEditDialog(item)"></d-btn-icon>
          <d-btn-icon type="delete" @click="deleteService_(item)"></d-btn-icon>
        </div>
      </template>
      <template v-slot:footer="{ props }">
        <v-divider></v-divider>
        <div class="d-flex flex-wrap justify-space-between align-center">
          <v-btn
            v-text="'+ ' + $t('service.createService')"
            class="d-btn-1 my-3 ml-4"
            small
            depressed
            tabindex="2"
            @click="openEditDialog(null)"
          ></v-btn>
          <v-data-footer
            :pagination="props.pagination"
            :options="props.options"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            class="d-border-none text-no-wrap"
          />
        </div>
      </template>
    </v-data-table>

    <service-edit-dialog
      :dialog.sync="editDialog"
      :service="editDialog_service"
      :vehicle-id="vehicleId"
      @create="(service) => $emit('service-create', service)"
    ></service-edit-dialog>
  </div>
</template>


<script>
import { mapActions } from 'vuex'

import dateTimeUtil from '@/utils/dateTime.util'

import snackbarMixin from '@/mixins/snackbar.mixin'

import DBtnIcon from '@/components/DBtnIcon'
import ServiceEditDialog from './ServiceEditDialog'


export default {
  props: {
    vehicleId: String,
    items: Array,
    loading: Boolean
  },


  components: {
    DBtnIcon,
    ServiceEditDialog
  },


  mixins: [
    snackbarMixin
  ],


  data () {
    return {
      dateTimeUtil,
      editDialog: false,
      editDialog_service: null
    }
  },


  computed: {
    tableHeaders () {
      return [
        { text: this.$t('service.table.date'), value: 'date', cellClass: 'text-no-wrap' },
        { text: this.$t('service.table.type'), value: 'serviceType.name' },
        { text: this.$t('service.table.mileage'), value: 'mileage' },
        { text: this.$t('service.table.glassProtection'), value: 'glassProtection' },
        { text: this.$t('service.table.dealer'), value: 'dealer.name' },
        // { text: this.$t('service.table.salesPerson'), value: 'salesPerson.emailAddress' }, //temporary hiding sales person from the project
        { text: this.$t('service.table.comment'), value: 'comment', cellClass: 'd-overflow-ellipsis' },
        { text: '', value: 'actions', align: 'end', width: 1, sortable: false, filterable: false }
      ]
    }
  },


  methods: {
    ...mapActions('service', ['deleteService']),

    openEditDialog (service) {
      this.editDialog_service = service
      this.editDialog = true
    },

    async deleteService_ (service) {
      try {
        const confirm = await this.$confirm(this.$t('service.deleteServiceConfirm'), { trueText: this.$t('delete') })

        if (confirm === true) {
          this.showSnackbarProcessing()
          await this.deleteService(service)
          this.showSnackbarSuccess()
        }
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      }
    }
  }
}
</script>
