<template>
  <v-input
    :value="value"
    :rules="$attrs.rules"
    :hide-details="attrs.hideDetails"
    @update:error="onError"
  >
    <v-text-field
      ref="input"
      v-model.trim="model"
      v-bind="$attrs"
      :rules="[]"
      :error="error"
      hide-details
      @focus="onFocus"
      @blur="onBlur"
    ></v-text-field>
  </v-input>
</template>


<script>
import PhoneNumber from 'awesome-phonenumber'


export default {
  inheritAttrs: false,


  props: {
    value: {
      type: String
    },
    defaultCountry: {
      type: String
    }
  },

  data () {
    return {
      error: false,
      caretPosition: 0
    }
  },


  computed: {
    model: {
      get () {
        let phone = this.value

        if (phone) {
          const phoneNumber = new PhoneNumber(phone)
          phone = phoneNumber.getNumber() || phone
        }

        return phone
      },
      set (val) {
        let phone = val

        if (phone) {
          const phoneNumber = new PhoneNumber(phone)
          phone = phoneNumber.getNumber() || phone
        }

        this.$emit('input', phone)
      }
    },

    attrs () {
      return {
        hideDetails: this.$attrs.hasOwnProperty('hide-details') ? (this.$attrs['hide-details'] ?? false) : false
      }
    },

    defaultCountryCode () {
      if (this.defaultCountry) {
        return '+' + PhoneNumber.getCountryCodeForRegionCode(this.defaultCountry)
      }
      return null
    }
  },


  methods: {
    onFocus () {
      if (this.defaultCountry && !this.value) {
        this.model = this.defaultCountryCode
      }
    },

    onBlur () {
      if (this.defaultCountry && this.value === this.defaultCountryCode) {
        this.model = ''
      }
    },

    onError (value) {
      this.error = value
    }
  }
}
</script>
