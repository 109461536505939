<template>
  <v-dialog
    :value="dialog"
    :max-width="500"
    content-class="d-dialog"
    scrollable
    @keydown.esc="closeDialog"
    @click:outside="closeDialog"
  >
    <v-card outlined class="d-dialog-card">
      <v-card-title>
        {{ $t('vehicle.sendSMS.title') }}
        <d-btn-icon
          type="close"
          btn-class="d-dialog-btn-close"
          tabindex="4"
          @click="closeDialog"
        ></d-btn-icon>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text>
        <!--        :rules="rules.sms"-->
        <v-form ref="form" @submit.stop.prevent>
          <v-textarea
            v-model.trim="editedSMS"
            :rules="smsRules"
            :label="$t('vehicle.sendSMS.labelSMStext')"
            rows="3"
            tabindex="1"
            auto-grow
            clearable
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          v-text="$t('cancel')"
          color="secondary"
          text
          tabindex="3"
          @click="closeDialog"
        ></v-btn>
        <v-btn
          v-text="$t('vehicle.sendSMS.btnSendSMS')"
          color="primary"
          text
          tabindex="2"
          @click="submit"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapActions } from 'vuex'

import { templateSchema } from '@/constants'

import dialogMixin from '@/mixins/dialog.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import validationMixin from '@/mixins/validation.mixin'
import errorMixin from '@/mixins/error.mixin'

import DBtnIcon from '@/components/DBtnIcon'


export default {
  props: {
    editedVehicle: Object
  },

  components: {
    DBtnIcon
  },

  mixins: [
    dialogMixin,
    snackbarMixin,
    validationMixin,
    errorMixin
  ],


  data () {
    return {
      editedSMS: '',

      smsRules: [
        v => !!v || this.$t('validation.smsTextRequired'),
        v => (v && v.length <= templateSchema.smsMaxLength) || this.$t('validation.smsTextLength')
      ]
    }
  },


  computed: {
    rulesToApply () {
      const rule = this.rule

      return {
        sms: [rule.sms()]
      }
    }
  },


  methods: {
    ...mapActions('template', ['loadSMSByLicenseNumber', 'sendSMS']),

    async initSMS () {
      const response = await this.loadSMSByLicenseNumber({ dealerId: this.editedVehicle.dealerId, licenseNumber: this.editedVehicle.licenseNumber })
      return response.sms
    },

    async onDialogOpen () {
      this.editedSMS = await this.initSMS()
      this.resetValidation()
    },

    async submit () {
      const valid = await this.validate(this.rulesToApply)

      if (!valid) return

      try {
        this.showSnackbarProcessing()
        const response = await this.sendSMS({ dealerId: this.editedVehicle.dealerId, licenseNumber: this.editedVehicle.licenseNumber, sms: this.editedSMS })
        this.$emit('sent', response)
        this.showSnackbarSuccess()
        this.closeDialog()
      } catch (error) {
        if (this.is409ConflictError(error)) {
          this.hideSnackbar()
          this.existingServices.push(service)
        } else {
          this.showSnackbarError()
        }
        this.$log.error(error)
      }
    }
  }
}
</script>
