<template>
  <div class="d-page-wrapper">
    <div class="d-page-toolbar">
      <d-search-input
        v-model="search"
        @submit="onSearchSubmit"
      ></d-search-input>
      <v-btn
        v-text="'+ ' + $t('vehicle.createVehicle')"
        class="d-btn-1 ml-auto"
        :color="currentDealerId ? '' : 'grey lighten-1'"
        depressed
        @click="openLicenseNumberDialog"
      ></v-btn>
    </div>
<!--    <pre class="col-12 text-blue-8 q-pa-md q-ma-md" style="border: 2px pink dotted; border-radius: 10px;">tableItems = {{ tableItems }}</pre>-->

<!--    :page="pageNumber"-->
<!--    :items-per-page="pageSize"-->
<!--    :server-items-length="totalCount"-->
    <v-data-table
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :headers="tableHeaders"
      :items="tableItems"
      class="d-data-table d-data-table--rows-clickable"
      @page-count="pageCount = $event"
      @click:row="openEditDialog"
    >
      <template v-slot:[`item.licenseNumber`]="{ item }">
        <v-icon class="d-data-table-cell-icon">mdi-car-settings</v-icon>
        {{ item.licenseNumber }}
      </template>
      <template v-slot:[`item.lastTreatment`]="{ item }">
        <template v-if="item.lastTreatment">{{ dateTimeUtil.utcToLocalDisplayDateFormat(item.lastTreatment) }}</template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="text-no-wrap d-visible-on-row-hover">
          <d-btn-icon type="edit" @click="openEditDialog(item)"></d-btn-icon>
          <d-btn-icon type="delete" @click="deleteVehicle_(item)"></d-btn-icon>
        </div>
      </template>
    </v-data-table>

<!--    <v-pagination-->
<!--      v-model="pageNumber"-->
<!--      :length="pageCount"-->
<!--      :total-visible="15"-->
<!--      class="my-5"-->
<!--      @input="onPageInput"-->
<!--    ></v-pagination>-->

    <vehicle-license-number-dialog
      :dialog.sync="licenseNumberDialog"
      @create="openCreateDialog"
      @edit="openEditDialog"
      @moved="onVehicleMoved"
    ></vehicle-license-number-dialog>

    <vehicle-create-dialog
      :dialog.sync="createDialog"
      :license-number="createDialog_licenseNumber"
      @create="openEditDialog"
    ></vehicle-create-dialog>

    <vehicle-edit-dialog
      :dialog.sync="editDialog"
      :vehicle="editDialog_vehicle"
    ></vehicle-edit-dialog>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { vehiclesPageSize } from '@/constants'

import pageMixin from '@/mixins/page.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'

import dateTimeUtil from '@/utils/dateTime.util'

import DSearchInput from '@/components/DSearchInput'
import DBtnIcon from '@/components/DBtnIcon'
import VehicleLicenseNumberDialog from './VehicleLicenseNumberDialog'
import VehicleCreateDialog from './VehicleCreateDialog'
import VehicleEditDialog from './VehicleEditDialog/index'


export default {
  mixins: [
    pageMixin,
    snackbarMixin
  ],


  components: {
    DSearchInput,
    DBtnIcon,
    VehicleLicenseNumberDialog,
    VehicleCreateDialog,
    VehicleEditDialog
  },


  data () {
    return {
      dateTimeUtil,
      sortBy: 'lastTreatment',
      sortDesc: true,
      search: null,
      pageCount: 0,
      pageNumber: 1,
      pageSize: vehiclesPageSize,
      licenseNumberDialog: false,
      createDialog: false,
      createDialog_licenseNumber: '',
      editDialog: false,
      editDialog_vehicle: null
    }
  },


  computed: {
    ...mapGetters('dealer', ['currentDealerId', 'previousDealerId']),
    ...mapGetters('vehicle', ['vehiclesTableItems', 'vehiclesTotalCount']),
    ...mapGetters('user', ['currentUser', 'currentUserIsChainAdmin']),
    ...mapGetters('chain', ['currentChainId']),

    tableHeaders () {
      return [
        { key: 'licenseNumber', text: this.$t('vehicle.table.licenseNumber'), value: 'licenseNumber', cellClass: 'font-weight-medium text-no-wrap' },
        { key: 'brand', text: this.$t('vehicle.table.brand'), value: 'brand' },
        { key: 'model', text: this.$t('vehicle.table.model'), value: 'model' },
        { key: 'firstName', text: this.$t('vehicle.table.firstName'), value: 'firstName' },
        { key: 'lastName', text: this.$t('vehicle.table.lastName'), value: 'lastName' },
        { key: 'address', text: this.$t('vehicle.table.address'), value: 'address' },
        { key: 'zipCode', text: this.$t('vehicle.table.zipCode'), value: 'zipCode' },
        { key: 'area', text: this.$t('vehicle.table.area'), value: 'area' },
        { key: 'lastTreatment', text: this.$t('vehicle.table.lastTreatment'), value: 'lastTreatment' },
        { key: 'actions', text: '', value: 'actions', align: 'end', width: 1, sortable: false, filterable: false }
      ]
    },

    tableItems () {
      return this.vehiclesTableItems(this.currentDealerId)
    },

    totalCount () {
      return this.vehiclesTotalCount(this.currentDealerId)
    }
  },


  watch: {
    async currentDealerId () {
      this.restoreInitialVehiclesTableItems(this.previousDealerId)
      this.search = null
      await this.reloadTableItems(1, true)
    },
    async currentChainId () {
      this.restoreInitialVehiclesTableItems(this.previousDealerId)
      this.search = null
      await this.reloadTableItems(1, true)
    }
  },


  methods: {
    ...mapActions('vehicle', ['loadVehiclesTableItems', 'restoreInitialVehiclesTableItems', 'deleteVehicle', 'loadVehicleBrands']),
    ...mapActions('user', ['loadUsersByChainId']),


    openLicenseNumberDialog () {
      if (!this.currentDealerId) {
        this.showSnackbar(this.$t('vehicle.createVehicleDisabledTooltip'), 3000)
      } else {
        this.licenseNumberDialog = true
      }
    },

    openCreateDialog (licenseNumber) {
      this.createDialog_licenseNumber = licenseNumber
      this.createDialog = true
    },

    openEditDialog (vehicle) {
      this.editDialog_vehicle = vehicle
      this.editDialog = true
    },

    onVehicleMoved (vehicle) {
      this.reloadTableItems()
    },

    onSearchSubmit () {
      // this.reloadTableItems(1)
      this.reloadTableItems()
    },

    onPageInput () {
      this.reloadTableItems()
    },

    async reloadTableItems (page, isInitial = false) {
      try {
        this.startPageLoading()

        if (page && page !== this.pageNumber) {
          this.pageNumber = page
        }
        const chainId = this.currentUserIsChainAdmin ? this.currentChainId : this.currentUser.chainId
        const items = await this.loadVehiclesTableItems({
          chainId,
          dealerId: this.currentDealerId,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          search: this.search,
          isInitial
        })

        return items
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      } finally {
        this.stopPageLoading()
      }
    },

    async deleteVehicle_ (vehicle) {
      try {
        const confirm = await this.$confirm(this.$t('vehicle.deleteVehicleConfirm', vehicle), { trueText: this.$t('delete') })

        if (confirm === true) {
          this.showSnackbarProcessing()
          await this.deleteVehicle(vehicle)
          this.showSnackbarSuccess(this.$t('vehicle.vehicleDeleted'))

          if (!this.tableItems.length) {
            const page = (this.pageNumber - 1) || 1
            this.reloadTableItems(page)
          }
        }
      } catch (error) {
        this.$log.error(error)
      }
    }
  },


  mounted () {
    this.restoreInitialVehiclesTableItems(this.currentDealerId)
  },


  async created () {
    try {
      this.setPageTitle(this.$t('nav.vehicles'))
      this.startPageLoading()
      if (this.currentUserIsChainAdmin) {
        await this.loadUsersByChainId()
      }
      const chainId = this.currentUserIsChainAdmin ? this.currentChainId : this.currentUser.chainId
      // await this.loadVehiclesTableItems({
      //   chainId,
      //   dealerId: this.currentDealerId,
      //   pageNumber: this.pageNumber,
      //   pageSize: this.pageSize,
      //   isInitial: true
      // })
      await this.loadVehiclesTableItems({
        chainId,
        dealerId: this.currentDealerId,
        pageNumber: 1,
        pageSize: 100000,
        isInitial: true
      })

      this.loadVehicleBrands()
    } catch (error) {
      this.showSnackbarError()
      this.$log.error(error)
    } finally {
      this.stopPageLoading()
    }
  }
}
</script>
