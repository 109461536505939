<template>
  <v-dialog
    v-if="editedService"
    :value="dialog"
    :persistent="isModelChanged()"
    :max-width="500"
    content-class="d-dialog"
    scrollable
    @keydown.esc="escape(closeDialog)"
    @click:outside="escape(closeDialog)"
  >
    <v-card outlined class="d-dialog-card">
      <v-card-title>
        {{ service ? $t('service.editService') : $t('service.createService') }}
        <d-btn-icon
          type="close"
          btn-class="d-dialog-btn-close"
          tabindex="4"
          @click="escape(closeDialog)"
        ></d-btn-icon>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form" @submit.stop.prevent>
          <v-alert
            v-if="validated && isServiceExistsError"
            v-text="$t('service.edit.serviceExistsError')"
            type="error"
            class="text-body-2"
            dense
            text
          ></v-alert>
          <v-row>
            <v-col cols="12" sm="6">
              <d-date-picker
                v-model="editedService.date"
                :rules="rules.date"
                :label="$t('service.edit.date')"
                tabindex="1"
              ></d-date-picker>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="editedService.mileage"
                :rules="rules.mileage"
                :label="$t('service.edit.mileage')"
                :suffix="$t('service.mileageSuffix')"
                type="number"
                tabindex="1"
                autofocus
              ></v-text-field>
            </v-col>
          </v-row>
          <v-select
            v-model="editedService.serviceTypeId"
            :rules="rules.serviceTypeId"
            :label="$t('service.edit.serviceType')"
            :menu-props="{ offsetY: true }"
            :items="serviceTypeItems"
            item-value="id"
            item-text="name"
            tabindex="1"
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item v-on="on" v-bind="attrs" :class="{ 'd-list-item--disabled': !item.isActive }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span :class="{ 'd-selection--disabled': !item.isActive }">
                {{ item.name }}
              </span>
            </template>
          </v-select>
<!--         temporary hiding sales person from the project-->
<!--          <v-select-->
<!--            v-model="editedService.userId"-->
<!--            :rules="rules.salesPersonUserId"-->
<!--            :label="$t('service.edit.salesPerson')"-->
<!--            :menu-props="{ offsetY: true }"-->
<!--            :items="salesPersonItems"-->
<!--            item-value="id"-->
<!--            item-text="name"-->
<!--            tabindex="1"-->
<!--          >-->
<!--            <template v-slot:item="{ item, on, attrs }">-->
<!--              <v-list-item v-on="on" v-bind="attrs" :class="{ 'd-list-item&#45;&#45;disabled': !item.isActive }">-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title>-->
<!--                    {{ item.name }}-->
<!--                  </v-list-item-title>-->
<!--                </v-list-item-content>-->
<!--              </v-list-item>-->
<!--            </template>-->
<!--            <template v-slot:selection="{ item }">-->
<!--              <span :class="{ 'd-selection&#45;&#45;disabled': !item.isActive }">-->
<!--                {{ item.name }}-->
<!--              </span>-->
<!--            </template>-->
<!--          </v-select>-->
          <v-checkbox
            v-model="editedService.glassProtection"
            :label="$t('service.edit.glassProtection')"
            tabindex="1"
          ></v-checkbox>
          <v-textarea
            v-model.trim="editedService.comment"
            :rules="rules.comment"
            :label="$t('service.edit.comment')"
            rows="1"
            tabindex="1"
            auto-grow
            clearable
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          v-text="$t('cancel')"
          color="secondary"
          text
          tabindex="3"
          @click="closeDialog"
        ></v-btn>
        <v-btn
          v-text="$t('save')"
          :disabled="!isModelChanged()"
          color="primary"
          text
          tabindex="2"
          @click="submit"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { serviceSchema, dateTimeFormats } from '@/constants'
import moment from 'moment'

import objectUtil from '@/utils/object.util'

import dialogMixin from '@/mixins/dialog.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import validationMixin from '@/mixins/validation.mixin'
import unsavedChangesMixin from '@/mixins/unsavedChanges.mixin'
import errorMixin from '@/mixins/error.mixin'

import DBtnIcon from '@/components/DBtnIcon'
import DDatePicker from '@/components/DDatePicker'


export default {
  props: {
    service: Object,
    vehicleId: String
  },


  components: {
    DBtnIcon,
    DDatePicker
  },


  mixins: [
    dialogMixin,
    snackbarMixin,
    validationMixin,
    unsavedChangesMixin,
    errorMixin
  ],


  data () {
    return {
      editedService: null,
      defaultService: {
        serviceTypeId: '',
        glassProtection: false,
        date: null,
        mileage: null,
        comment: ''
      },
      existingServices: []
    }
  },


  computed: {
    ...mapGetters('service', ['servicesByVehicle']),
    ...mapGetters('serviceType', ['serviceTypes']),
    ...mapGetters('dealer', ['currentDealerId']),
    ...mapGetters('user', ['usersByChainId']),

    rulesToApply () {
      const rule = this.rule

      return {
        date: [rule.required()],
        mileage: [rule.required()],
        serviceTypeId: [rule.required()],
        // salesPersonUserId: [rule.required()],
        // salesPersonUserId: [],
        comment: [rule.maxLength(serviceSchema.commentMaxLength)]
      }
    },

    isServiceExistsError () {
      return !!this.existingServices.find(x =>
        x.date === this.editedService.date &&
        x.serviceTypeId === this.editedService.serviceTypeId)
    },

    serviceTypeItems () {
      const items = this.serviceTypes.map(serviceType => {
        const item = { ...serviceType }
        if (item.isActive === false) {
          item.name += ` [${this.$t('serviceType.inactive')}]`
        }
        return item
      })

      const sortedItems = items.sort((x, y) => {
        if (x.isActive === y.isActive) return x.name.localeCompare(y.name)
        if (x.isActive === true) return -1
        return 1
      })

      return sortedItems
    },

    salesPersonItems () {
      const items = this.usersByChainId.map(user => {
        const item = { ...user }
        item.name = item.emailAddress
        if (item.isActive === false) {
          item.name += ` [${this.$t('serviceType.inactive')}]`
        }
        return item
      })

      const sortedItems = items.sort((x, y) => {
        if (x.isActive === y.isActive) return x.name.localeCompare(y.name)
        if (x.isActive === true) return -1
        return 1
      })

      const filteredItems = sortedItems.filter(item => item.roleId === 5)

      filteredItems.unshift({
        id: '',
        name: this.$t('service.edit.noSalesPersonOption')
      })

      return filteredItems
    }
  },


  methods: {
    ...mapActions('service', ['createService', 'updateService']),

    onDialogOpen () {
      const service = objectUtil.getObjectCopy(this.service || this.defaultService)

      if (!service.serviceTypeId && this.serviceTypes.length) {
        service.serviceTypeId = this.serviceTypeItems[0].id
      }
      if (!service.date) {
        service.date = moment().format(dateTimeFormats.dateUtc)
      }

      this.editedService = service
      this.existingServices = this.servicesByVehicle(this.vehicleId).filter(x => x.id !== service.id)

      this.initWatchedModel(this.editedService)
      this.resetValidation()
    },

    async submit () {
      const valid = await this.validate(this.rulesToApply)
      if (!valid || this.isServiceExistsError || !this.isModelChanged()) return

      let service = objectUtil.getObjectCopy(this.editedService)

      try {
        this.showSnackbarProcessing()

        if (service.id) {
          await this.updateService(service)
        } else {
          service.vehicleId = this.vehicleId
          service.dealerId = this.currentDealerId
          service = await this.createService(service)
          this.$emit('create', service)
        }

        this.showSnackbarSuccess()
        this.closeDialog()
      } catch (error) {
        if (this.is409ConflictError(error)) {
          this.hideSnackbar()
          this.existingServices.push(service)
        } else {
          this.showSnackbarError()
        }
        this.$log.error(error)
      }
    }
  }
}
</script>
