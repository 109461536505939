<template>
  <v-dialog
    :value="dialog"
    :max-width="350"
    content-class="d-dialog"
    @keydown.enter="submit"
    @keydown.esc="closeDialog"
    @click:outside="closeDialog"
  >
    <v-card outlined class="d-dialog-card">
      <v-card-title>
        {{ $t('vehicle.licenseNumberDialog.title') }}
        <d-btn-icon
          type="close"
          btn-class="d-dialog-btn-close"
          tabindex="4"
          @click="closeDialog"
        ></d-btn-icon>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form" @submit.stop.prevent>
          <v-text-field
            ref="licenseNumber"
            v-model.trim="licenseNumber"
            :rules="rules.licenseNumber"
            :placeholder="$t('vehicle.edit.licenseNumber')"
            :hint="$t('vehicle.edit.licenseNumber_hint')"
            tabindex="1"
            autofocus
            @keydown="preventWhitespaceInput"
            @input="onLicenseNumberInput"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          v-text="$t('cancel')"
          color="secondary"
          text
          tabindex="3"
          @click="closeDialog"
        ></v-btn>
        <v-btn
          v-text="$t('next')"
          :disabled="!licenseNumber"
          color="primary"
          text
          tabindex="2"
          @click="submit"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { vehicleSchema } from '@/constants'

import dialogMixin from '@/mixins/dialog.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import validationMixin from '@/mixins/validation.mixin'
import errorMixin from '@/mixins/error.mixin'
import licenseNumberMixin from '@/mixins/licenseNumber.mixin'

import DBtnIcon from '@/components/DBtnIcon'


export default {
  components: {
    DBtnIcon
  },


  mixins: [
    dialogMixin,
    snackbarMixin,
    validationMixin,
    errorMixin,
    licenseNumberMixin
  ],


  data () {
    return {
      licenseNumber: ''
    }
  },


  computed: {
    ...mapGetters('dealer', ['currentDealerId', 'shortDealerById']),
    ...mapGetters('user', ['currentUser', 'currentUserIsChainAdmin']),
    ...mapGetters('chain', ['currentChainId']),

    rulesToApply () {
      const rule = this.rule

      return {
        licenseNumber: [rule.required(), rule.maxLength(vehicleSchema.licenseNumberMaxLength)]
      }
    }
  },


  methods: {
    ...mapActions('vehicle', ['getVehicleByLicenseNumber', 'moveVehicle']),

    onDialogOpen () {
      this.licenseNumber = ''
      this.resetValidation()
    },

    onLicenseNumberInput () {
      this.licenseNumber = this.fixLicenseNumber(this.licenseNumber)
    },

    async getVehicleByLicenseNumber_ (licenseNumber, chainId) {
      let vehicle = null

      try {
        this.showSnackbarProcessing()
        vehicle = await this.getVehicleByLicenseNumber({ licenseNumber, chainId })
      } catch (error) {
        if (!this.is404NotFoundError(error)) {
          this.hideSnackbar()
          throw error
        }
      }

      this.hideSnackbar()

      return vehicle
    },

    async submit () {
      const valid = await this.validate(this.rulesToApply)
      if (!valid) return

      try {
        let vehicle = await this.getVehicleByLicenseNumber_(this.licenseNumber, this.currentUser.chainId)

        if (!vehicle) {
          this.$emit('create', this.licenseNumber)
          this.closeDialog()
        } else if (vehicle.dealerId === this.currentDealerId) {
          this.$emit('edit', vehicle)
          this.closeDialog()
        } else {
          const dealerName = this.shortDealerById(vehicle.dealerId)?.name

          const confirmMoving = await this.$confirm(this.$t('vehicle.moveVehicleConfirm',
            { licenseNumber: this.licenseNumber, dealer: dealerName }), { trueText: this.$t('vehicle.moveVehicle') })

          if (confirmMoving) {
            this.showSnackbarProcessing()
            const chainId = this.currentUserIsChainAdmin ? this.currentChainId : this.currentUser.chainId
            vehicle = await this.moveVehicle({ dealerId: this.currentDealerId, vehicle, chainId })
            this.showSnackbarSuccess(this.$t('vehicle.vehicleMoved'))
            this.$emit('moved', vehicle)
            this.$emit('edit', vehicle)
            this.closeDialog()
          } else {
            this.$refs.licenseNumber.focus()
          }
        }
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      }
    }
  }
}
</script>
