<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="items"
      :loading="loading"
      :loader-height="2"
      sort-by="sentDateTimeUtc"
      sort-desc
      class="d-data-table d-data-table--filled d-data-table--rows-clickable"
      @click:row="openViewDialog"
    >
      <template v-slot:[`item.sentDateTimeUtc`]="{ item }">
        {{ dateTimeUtil.utcToLocalDisplayDateTimeFormat(item.sentDateTimeUtc) }}
      </template>
      <template v-slot:[`item.notificationTypeId`]="{ item }">
        {{ (getNotificationTypeById(item.notificationTypeId) || {}).name }}
      </template>
      <template v-slot:[`item.isManual`]="{ item }">
        <d-check-icon :value="item.isManual"></d-check-icon>
      </template>
    </v-data-table>

    <notification-view-dialog
      :dialog.sync="viewDialog"
      :notification="viewedNotification"
    ></notification-view-dialog>
  </div>
</template>


<script>
import dateTimeUtil from '@/utils/dateTime.util'

import notificationTypeMixin from '@/mixins/notificationType.mixin'

import NotificationViewDialog from '@/views/Notifications/NotificationViewDialog'
import DCheckIcon from '@/components/DCheckIcon'

export default {
  props: {
    items: Array,
    loading: Boolean
  },


  components: {
    NotificationViewDialog,
    DCheckIcon
  },


  mixins: [
    notificationTypeMixin
  ],


  data () {
    return {
      dateTimeUtil,
      viewDialog: false,
      viewedNotification: null
    }
  },


  computed: {
    tableHeaders () {
      return [
        { text: this.$t('notification.table.sentDateTime'), value: 'sentDateTimeUtc', cellClass: 'text-no-wrap' },
        { text: this.$t('notification.table.type'), value: 'notificationTypeId' },
        { text: this.$t('notification.table.isManual'), value: 'isManual' },
        { text: this.$t('notification.table.subject'), value: 'subject', cellClass: 'd-overflow-ellipsis d-notifications-table-cell-subject' },
        { text: this.$t('notification.table.message'), value: 'message', cellClass: 'd-overflow-ellipsis d-notifications-table-cell-message' }
      ]
    }
  },


  methods: {
    openViewDialog (notification) {
      this.viewDialog = true
      this.viewedNotification = notification
    }
  }
}
</script>
