const mixin = {
  methods: {
    fixLicenseNumber (licenseNumber) {
      return licenseNumber.toUpperCase().replace(/\s/g, '')
    }
  }
}


export default mixin
