<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="auto"
    bottom
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="displayDate"
        v-bind="$attrs"
        v-on="on"
        :class="inputClass"
        readonly
        @click:clear="date=null"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :min="min"
      :max="max"
      color="primary"
      no-title
      @input="menu = false"
      @change="$emit('change')"
    ></v-date-picker>
  </v-menu>
</template>


<script>
import moment from 'moment'
import { dateTimeFormats } from '@/constants'


export default {
  inheritAttrs: false,


  props: {
    value: {
      type: String
    },
    inputClass: {
      type: String
    },
    min: {
      type: String
    },
    max: {
      type: String
    }
  },


  data () {
    return {
      menu: false
    }
  },


  computed: {
    date: {
      get () {
        return this.value ? moment(this.value).format(dateTimeFormats.datePicker) : null
      },
      set (val) {
        const date = val ? moment(val).format(dateTimeFormats.dateUtc) : null
        this.$emit('input', date)
      }
    },

    displayDate () {
      return this.date ? moment(this.date).format(dateTimeFormats.displayDate) : ''
    }
  }
}
</script>
